import styled from 'styled-components';

import { media } from 'styles/mixins';

import Image from 'components/Image';

const Cards = styled.div`
  grid-template-columns: repeat(2, 1fr);
  margin-top: 3.5rem;
  gap: var(--gap);
  display: grid;

  ${media.mobileM`
    grid-template-columns: 1fr;
    margin-top: 2rem;
  `}
`;

const Card = styled.div`
  background-color: var(--card-background-color);
  row-gap: 1.75rem;
  display: grid;
  padding: 2rem;

  ${media.mobileM`
    row-gap: 1rem;
    padding: 2rem 0;
    background-color: transparent;
  `}
`;

const Top = styled.div`
  grid-template-columns: auto 1fr;
  column-gap: 2.25rem;
  align-items: center;
  justify-content: center;
  display: grid;

  ${media.tabletS`
    row-gap: 1rem;
    grid-template-columns: auto;
  `}

  h4 {
    padding-bottom: 0;

    ${media.tabletS`
      text-align: center;
    `}
  }
`;

const Photo = styled(Image)`
  border-radius: 50%;
  height: 8.25rem;
  width: 8.25rem;

  ${media.tabletS`
    height: 6.25rem;
    width: 6.25rem;
    margin: 0 auto;
  `}
`;

const Bottom = styled.div`
  grid-template-columns: 1fr auto;
  column-gap: 2.625rem;
  align-items: center;
  display: grid;

  ${media.tablet`
    grid-template-columns: auto;
    row-gap: 1rem;
  `}

  button {
    ${media.mobileM`
      margin: 0 auto;
    `}
  }
`;

const About = styled.p`
  font-size: 1.125rem;
  line-height: 1.22em;

  ${media.tabletS`
    text-align: center;
    font-size: 1rem;
  `}
`;

export { Cards, Card, Top, Photo, Bottom, About };
