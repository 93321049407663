import { graphql } from 'gatsby';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { MainContainer, CardsContainer } from 'components/UI/Containers';

import GallerySection from 'templates/Home/GallerySection';
import Card from 'components/Card';

import HeroSection from './HeroSection';
import AboutCourse from './AboutCourse';
import TeachersSection from './TeachersSection';
import UniversitiesSection from './UniversitiesSection';

const HomeTemplate = ({ data, pageContext }) => {
  const {
    datoCmsHomePage: pageData,
    datoCmsRegistrationPage: { registrationPageId },
    allDatoCmsTeacher: { edges: teachers },
    allDatoCmsUniversity: { edges: universities },
  } = data;
  const {
    seo,
    title,
    subtitle,
    registrationOpen,
    registrationOpenText,
    registrationClosed,
    infoBlocks,
  } = pageData;
  const {
    aboutTitle,
    aboutSubtitle,
    aboutList,
    aboutLessonTitle,
    aboutLessonSubtitle,
    aboutLessonVideo,
    aboutLessonVideoPreview,
  } = pageData;
  const {
    teachersTitle,
    teachersSubtitle,
    studentsTitle,
    studentsSubtitle,
    galleryTitle,
    gallerySubtitle,
    gallery,
  } = pageData;
  const { seoTitle, seoDescription, seoImage } = seo || {};

  return (
    <PageWrapper
      pageData={pageContext}
      {...{ seoTitle, seoDescription, seoImage }}
    >
      <MainContainer>
        <section>
          <HeroSection
            title={title}
            subTitle={subtitle}
            isOpen={registrationOpen}
            registrationPageId={registrationPageId}
            registrationButton={registrationOpenText}
            joinButton={registrationClosed}
          />

          <CardsContainer>
            {infoBlocks.map(({ infoBlockTitle, infoBlockDetails }, index) => (
              <Card
                key={index}
                title={infoBlockTitle}
                description={infoBlockDetails}
              />
            ))}
          </CardsContainer>

          <AboutCourse
            title={aboutTitle}
            subTitle={aboutSubtitle}
            info={aboutList}
            courseTitle={aboutLessonTitle}
            courseSubTitle={aboutLessonSubtitle}
            video={aboutLessonVideo}
            videoPreview={aboutLessonVideoPreview}
          />

          <TeachersSection
            title={teachersTitle}
            subTitle={teachersSubtitle}
            data={teachers}
          />
        </section>
      </MainContainer>

      <UniversitiesSection
        title={studentsTitle}
        subTitle={studentsSubtitle}
        images={universities}
      />

      <MainContainer>
        <section>
          <GallerySection
            title={galleryTitle}
            subTitle={gallerySubtitle}
            images={gallery}
          />
        </section>
      </MainContainer>
    </PageWrapper>
  );
};

export default HomeTemplate;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    datoCmsHomePage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      subtitle {
        value
      }
      registrationOpen
      registrationOpenText
      registrationClosed
      chanelText
      infoBlocks {
        infoBlockTitle
        infoBlockDetails {
          value
        }
      }
      aboutTitle
      aboutSubtitle {
        value
      }
      aboutList {
        value
      }
      aboutLessonTitle
      aboutLessonSubtitle {
        value
      }
      aboutLessonVideo {
        url
        width
        height
      }
      aboutLessonVideoPreview {
        gatsbyImageData
      }
      teachersTitle
      teachersSubtitle {
        value
      }
      studentsTitle
      studentsSubtitle {
        value
      }
      galleryTitle
      gallerySubtitle {
        value
      }
      gallery {
        gatsbyImageData
      }
    }
    allDatoCmsTeacher(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id: originalId
          fullName
          about
          teacherPhoto {
            gatsbyImageData
          }
        }
      }
    }
    allDatoCmsUniversity(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          universityName
          universityLogo {
            gatsbyImageData
          }
        }
      }
    }
    datoCmsRegistrationPage(locale: { eq: $locale }) {
      registrationPageId: originalId
    }
  }
`;
