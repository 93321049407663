import { StructuredText } from 'react-datocms';

import { HeadingMedium, Title } from 'components/UI/Headings';
import { PlayButton } from 'components/UI/Buttons';

import Image from 'components/Image';

import { ShowOnlyOnDesktop, ShowOnlyOnMobile } from 'styles/elements';

import {
  Wrapper,
  Course,
  ImageWrapper,
  CourseInfo,
  PlayButtonWrapper,
  Description,
  Info,
  SubTitle,
  List,
} from './styles';

const AboutCourse = ({
  title,
  subTitle,
  info,
  courseTitle,
  courseSubTitle,
  video,
  videoPreview,
}) => {
  const openExternalLink = (url) => {
    if (url) return window.open(url, '_blank', 'noopener, noreferrer');

    return null;
  };

  return (
    <Wrapper>
      <ShowOnlyOnMobile>
        <Title isCenter>{title}</Title>
        <SubTitle>
          <StructuredText data={subTitle} />
        </SubTitle>
      </ShowOnlyOnMobile>
      <Course>
        <ImageWrapper>
          <Image image={videoPreview} alt={title} />
        </ImageWrapper>
        <CourseInfo>
          <PlayButtonWrapper>
            <PlayButton onClick={() => openExternalLink(video.url)} />
          </PlayButtonWrapper>
          <Title>{courseTitle}</Title>
          <Description>
            <StructuredText data={courseSubTitle} />
          </Description>
        </CourseInfo>
      </Course>
      <Info>
        <ShowOnlyOnDesktop>
          <HeadingMedium>{title}</HeadingMedium>
          <SubTitle>
            <StructuredText data={subTitle} />
          </SubTitle>
        </ShowOnlyOnDesktop>
        <List>
          <StructuredText data={info} />
        </List>
      </Info>
    </Wrapper>
  );
};

export default AboutCourse;
