import { StructuredText } from 'react-datocms';

import { MainContainer } from 'components/UI/Containers';
import { HeadingMedium, SubTitle } from 'components/UI/Headings';

import { Wrapper, Universities, University } from './styles';

const UniversitiesSection = ({ title, subTitle, images }) => {
  return (
    <Wrapper>
      <MainContainer>
        <HeadingMedium isCenter isDivider as="h3">
          {title}
        </HeadingMedium>
        <SubTitle>
          <StructuredText data={subTitle} />
        </SubTitle>
        <Universities>
          {images.map((block, key) => {
            const { universityName, universityLogo } = block.node;

            return (
              <div key={key}>
                <University image={universityLogo} alt={universityName} />
              </div>
            );
          })}
        </Universities>
      </MainContainer>
    </Wrapper>
  );
};

export default UniversitiesSection;
