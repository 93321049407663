import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.div`
  position: relative;
  margin-top: 5rem;

  ${media.mobileM`
    margin-top: 2rem;
  `}

  .swiper-slide {
    cursor: grab;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: var(--color-white);

    ${media.laptopS`
      display: none;
    `}

    &::after {
      font-size: 20px;
      font-weight: 800;
    }
  }

  .swiper-button-prev {
    transform: translateX(-100%);
    left: -0.9rem;
  }

  .swiper-button-next {
    transform: translateX(100%);
    right: -0.9rem;
  }
`;

export { Wrapper };
