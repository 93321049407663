import { useState, useRef, useEffect } from 'react';
import { useTransition, animated } from 'react-spring';

import { Wrapper } from './styles';

const AnimatedImage = ({ images, duration = 3000 }) => {
  const [index, setIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      setIndex((index + 1) % images.length);
      setNextIndex((nextIndex + 1) % images.length);
    }, duration);
    return () => clearTimeout(timerRef.current);
  }, [index, images.length, nextIndex, duration]);

  const transitions = useTransition(index, null, {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration },
    onRest: () => {
      setIndex(nextIndex);
      setNextIndex((nextIndex + 1) % images.length);
    },
  });

  return (
    <Wrapper>
      <img src={images[0]} alt="Reactor" />
      {transitions.map(({ item, props, key }) => (
        <animated.img
          key={key}
          src={images[item]}
          alt={`Reactor image ${item + 1}`}
          style={{
            ...props,
            top: 0,
            left: 0,
          }}
        />
      ))}
    </Wrapper>
  );
};

export default AnimatedImage;
