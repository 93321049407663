import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.div`
  position: relative;

  ${media.tabletS`
    width: 80%;
    margin: 0 auto;
  `}

  img {
    width: 100%;
  }
`;

export { Wrapper };
