import { StructuredText } from 'react-datocms';

import { Navigator } from 'components/Layout/Navigator';
import AnimatedImage from 'components/AnimatedImage';

import { HeadingBig, HeadingSmall } from 'components/UI/Headings';
import { HorizontalButtonContainer } from 'components/UI/Containers';
import { LightButton, TransparentButton } from 'components/UI/Buttons';

import { ShowOnlyOnDesktop, ShowOnlyOnMobile } from 'styles/elements';

import { Wrapper, Content, SubTitle } from './styles';

const reactorImages = [
  '/reactor-1.jpg',
  '/reactor-2.jpg',
  '/reactor-3.jpg',
  '/reactor-4.jpg',
  '/reactor-5.jpg',
  '/reactor-6.jpg',
];

const HeroSection = ({ title, subTitle, isOpen, registrationButton, registrationPageId, joinButton }) => {
  return (
    <Wrapper>
      <Content>
        <ShowOnlyOnDesktop>
          <HeadingBig>{title}</HeadingBig>
        </ShowOnlyOnDesktop>
        <SubTitle>
          <StructuredText data={subTitle} />
        </SubTitle>
        <HorizontalButtonContainer>
          {isOpen ? (
            <LightButton as={Navigator} recordId={registrationPageId}>{registrationButton}</LightButton>
          ) : (
            <TransparentButton>{joinButton}</TransparentButton>
          )}
        </HorizontalButtonContainer>
      </Content>
      <div>
        <ShowOnlyOnMobile>
          <HeadingSmall isCenter>{title}</HeadingSmall>
        </ShowOnlyOnMobile>
        <AnimatedImage images={reactorImages} />
      </div>
    </Wrapper>
  );
};

export default HeroSection;
