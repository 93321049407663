import { StructuredText } from 'react-datocms';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';

import Image from 'components/Image';
import { HeadingMedium, SubTitle } from 'components/UI/Headings';

import { Wrapper } from './styles';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const GallerySection = ({ title, subTitle, images }) => {
  const params = {
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      480: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      660: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
    },
  };

  return (
    <>
      <HeadingMedium isCenter isDivider as="h3">
        {title}
      </HeadingMedium>
      <SubTitle>
        <StructuredText data={subTitle} />
      </SubTitle>
      <Wrapper>
        <Swiper {...params}>
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <Image image={image} alt={`gallery-image-${index}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-prev" />
        <div className="swiper-button-next" />
      </Wrapper>
    </>
  );
};

export default GallerySection;
