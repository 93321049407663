import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 3rem;

  ${media.tabletS`
    display: flex;
    flex-direction: column-reverse;
  `}
`;

const Content = styled.div`
  height: fit-content;
  display: grid;
  row-gap: 2rem;

  ${media.tabletS`
    margin-top: 1.5rem;
    width: fit-content;
  `}
`;

const SubTitle = styled.div`
  font-size: 1.5rem;
  line-height: 1.33em;

  ${media.laptopS`
    font-size: 1.125rem;
  `}

  strong {
    display: block;
    color: var(--primary-color);
  }
`;

export { Wrapper, Content, SubTitle };
