import styled from 'styled-components';

import { media } from 'styles/mixins';

const Wrapper = styled.div`
  grid-template-columns: 3fr 4fr;
  margin: 8.5625rem 0;
  column-gap: 4.5rem;
  display: grid;

  ${media.tabletS`
    margin: 2rem 0 4.6875rem;
    grid-template-columns: auto;
  `}
`;

const Course = styled.div`
  position: relative;

  ${media.tabletS`
    margin-bottom: 3rem;
    height: 60vw;
    width: 100%;
  `}

  ${media.mobileM`
    margin-bottom: 2rem;
    height: 85vw;
  `}
`;

const ImageWrapper = styled.div`
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const CourseInfo = styled.div`
  position: absolute;
  padding-bottom: 2rem;
  text-align: center;
  width: 100%;
  bottom: 0;

  &::before {
    content: '';
    background-color: var(--color-dark-01);
    position: absolute;
    opacity: 0.7;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
`;

const PlayButtonWrapper = styled.div`
  margin-top: -3.4375rem;
  margin-bottom: 1.25rem;

  ${media.mobileM`
    margin-top: -2.25rem;
    margin-bottom: 0.5rem;
  `}
`;

const Description = styled.div`
  position: relative;

  ${media.mobileM`
    font-size: 0.75rem;
  `}
`;

const Info = styled.div`
  width: 75%;

  ${media.laptop`
    width: 100%;
  `}
`;

const SubTitle = styled.div`
  color: var(--primary-color);
  margin-bottom: 3rem;
  line-height: 1.33em;
  font-size: 1.375rem;

  ${media.laptopS`
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  `}

  strong {
    color: var(--primary-color);
  }
`;

const List = styled.div`
  font-size: 1.125rem;

  li {
    position: relative;
    padding-left: 1.25rem;

    &::before {
      content: '';
      border-radius: 50%;
      background-color: var(--primary-color);
      position: absolute;
      height: 0.5rem;
      width: 0.5rem;
      top: 0.625rem;
      left: 0;
    }

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;

export {
  Wrapper,
  Course,
  ImageWrapper,
  CourseInfo,
  PlayButtonWrapper,
  Description,
  Info,
  SubTitle,
  List,
};
