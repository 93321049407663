import { StructuredText } from 'react-datocms';

import { Navigator } from 'components/Layout/Navigator';
import { DarkButton } from 'components/UI/Buttons';
import { HeadingMedium, Title, SubTitle } from 'components/UI/Headings';

import { Cards, Card, Top, Photo, Bottom, About } from './styles';

const TeachersSection = ({ title, subTitle, data }) => {
  return (
    <>
      <HeadingMedium isCenter isDivider as="h3">
        {title}
      </HeadingMedium>
      <SubTitle>
        <StructuredText data={subTitle} />
      </SubTitle>
      <Cards>
        {data.map((block) => {
          const { id, fullName, about, teacherPhoto } = block.node;

          return (
            <Card key={id}>
              <Top>
                <Photo image={teacherPhoto} alt={fullName} />
                <Title>{fullName}</Title>
              </Top>
              <Bottom>
                <About>{about}</About>
                <DarkButton as={Navigator} recordId={id}>Детальніше</DarkButton>
              </Bottom>
            </Card>
          );
        })}
      </Cards>
    </>
  );
};

export default TeachersSection;
