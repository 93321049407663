import styled from 'styled-components';

import Image from 'components/Image';

import { media } from 'styles/mixins';

const Wrapper = styled.div`
  background-color: var(--color-dark-02);
  padding: 3.75rem 0;
  position: relative;
  overflow: hidden;
`;

const Universities = styled.div`
  justify-content: center;
  margin-top: 3.25rem;
  flex-wrap: wrap;
  display: flex;

  ${media.mobileM`
    margin-top: 1.5rem;
  `}

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 1.5rem;

    ${media.mobileM`
      margin: 0.75rem;
    `}
  }
`;

const University = styled(Image)`
  max-height: 7rem;
  max-width: 15rem;

  ${media.mobileM`
    max-width: 13.75rem;
  `}

  img {
    object-fit: contain !important; // override gatsby-plugin-image styles
  }
`;

export { Wrapper, Universities, University };
